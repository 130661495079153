  /* .interview-container {
    min-height: 100vh;
    height: 100%;
    background-color: #C5F7FF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  } */
  
  /* .interview-card {
    margin-top:  20px;
    width: 95%;
    background-color: #D1D5DB;
    border-radius: 1.5rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5rem;
    min-height: 85vh;
  } */
  
  /* .heading {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #374151;
  } */

  /* .heading p{
    background-color: #9CA3AF;
width: fit-content;
border-radius: 9999px;
padding: 0.5rem 1rem;
  } */
  
  /* .main-text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1F2937;
    line-height: 1.25;
  } */
  
  /* .main-text p {              
    -webkit-user-select: none; 
  } */
  
  /* .animation-container {
    display: flex;
    justify-content: center;
} */


.xAxis .recharts-cartesian-axis-ticks {
  display: none;
}


.speaker-container {
    position: fixed;
    /* top: 22rem; */
    height: 6rem;
    width: 600px;
    background-color: #C5F7FF;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.speaker-overlay {
    position: absolute;
    height: 100%;
    width: 50%;
    background: linear-gradient(100deg, #3ECFDA 40%, #0952DA 140.16%);
    border-radius: 9999px;
    transition: transform 0.9s ease; 
    z-index: 1;
}

.speaker-container.active-anamika .speaker-overlay {
    transform: translateX(0); 
}

.speaker-container.active-candidate .speaker-overlay {
    transform: translateX(100%);
}

.speaker {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #374151;
    z-index: 2;
}

.speaker.anamika , .speaker.candidate {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.voice-visualization {
    display: flex;
    gap: 0.3rem;
}

.bar {
    width: 0.25rem;
    height: 1.25rem;
    background-color: #ffffff;
    border-radius: 9999px;
    transition: transform 0.3s ease-in-out;
    transform: scaleY(1);
    outline: 0.2px solid white;
}

.bar.active {
    animation: speaking-animation 0.8s infinite ease-in-out;
}

@keyframes speaking-animation {
    0%, 100% {
        transform: scaleY(0.5);
    }
    50% {
        transform: scaleY(1.2);
    }
}

/* .next-button {
  position: fixed;
  bottom: 6rem;
  align-self: center;
  background-color: #9CA3AF;
  color: #1F2937;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
} */

.next-button:hover {
  background-color: #6B7280;
}

  @media (max-width: 640px) {
    .interview-card {
      padding: 1rem;
    }
  
    .main-text {
      font-size: 1.25rem;
    }
  
    .speaker-container {
      height: 8rem;
    }
  
    .speaker {
      width: 80%;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
    }
  
    .speaker.candidate {
      align-items: flex-end;
    }
  }




  .header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #343a40;
  }
  
  .header p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }
  
  .header .timer {
    font-weight: 600;
    color: #28a745;
  }
  
  @media (max-width: 600px) {
    .header {
      flex-direction: column;
      height: auto;
      padding: 10px;
    }
  
    .header p {
      font-size: 14px;
    }
  }

  .stoptestpage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .stoptestpage:hover {
    transform: scale(1.02);
  }
  
  #stoptest h2, #stoptest h3 {
    margin: 10px 0;
    color: #333;
  }
  
  #stoptest h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #d9534f;
  }
  
  #stoptest h3 {
    font-size: 1.2em;
    color: #5bc0de;
    font-weight: normal;
  }
  
  #stoptest h3:last-of-type {
    color: #5cb85c;
    margin-top: 20px;
  }

  .test-timer{
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 1000;
  }

  .thankYouPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6a0dad, #9400d3, #ff007f, #ff4500);
    color: white;
    text-align: center;
    padding: 30px;
  }
  
  .feedbackForm {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
    max-width: 500px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .interviewComplete h1,
  .interviewComplete h3 {
    margin: 10px 0;
    font-weight: bold;
    color: white;
  }
  
  .rating-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .star {
    font-size: 1.5em;
    cursor: pointer;
    color: #d3d3d3;
    transition: color 0.2s;
  }
  
  .star.selected {
    color: #ffd700;
  }
  
  #comments {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 10px;
    resize: none;
  }
  
  .nextQuestionBtn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .nextQuestionBtn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .feedbackForm {
      padding: 20px;
    }
    .star {
      font-size: 1.2em;
    }
    .nextQuestionBtn {
      padding: 8px 16px;
    }
  }
  
  .resumeButtonContainer {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    font-family: 'Arial', sans-serif;
    animation: fadeIn 0.5s ease-out;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .resumeDescription {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .resumeBtn {
    background-color: #4CAF50;
    color: #fff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .resumeBtn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .resumeBtn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(72, 165, 72, 0.6);
  }
  
  .resumeBtn:active {
    transform: translateY(2px);
  }
  