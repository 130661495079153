.app-background {
    background-image: url('../../public/BG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 100vh; */
  }  
  
.custom-modal {
    position: fixed;
    top: 15%;
    left: 15%;
    right: 15%;
    background-color: rgba(217, 217, 217, 1);
    padding: 20px;
    border-radius: 8px;
    gap: 10px;
    border: solid 1px black;
  }

  .custom-scheduler .react-calendar__navigation__label{
    font-size: 1rem;
  }
  
  .custom-scheduler .react-calendar__tile--active{
    background: linear-gradient(180deg, rgba(9, 55, 114, 1) 0%, rgba(15, 81, 167, 1) 100%) !important;
    border-radius: 5px;
    color: white;
  }
  
  .custom-scheduler .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background: linear-gradient(180deg, rgba(9, 55, 114, 1) 0%, rgba(15, 81, 167, 1) 100%) !important;
    border-radius: 10px;
  }

.amplify-input, .amplify-field__show-password{
    display: block;
    width: 100%;
    padding: 10px 18px;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 50px;
    color: #1f2937;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.amplify-button--primary{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    background: linear-gradient(267.39deg, #FB54AE 0.32%, #2F64FF 100.25%);
    border-radius: 50px;
    font-weight: 600;
    transition: all 0.15s ease-in-out;
    border: none;
    color:white;
}


.amplify-button--primary:hover {
    opacity: 0.9;
    transform: translateY(-0.125rem);
}

.amplify-button--link {
    background: linear-gradient(267.39deg, #FF60B6 0.32%, #2960FD 100.25%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 600;
    text-decoration: none;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.amplify-button--link:hover {
    background: linear-gradient(267.39deg, #FF60B6 0.32%, #2960FD 100.25%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity: 0.9;
    transform: translateY(-0.125rem);
}



.amplify-input:focus , .amplify-field__show-password:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(62, 207, 218, 0.5);
    border-color: transparent;
}
 .amplify-tabs__list {
    border-radius: 20px;
    display: none;
}

.amplify-heading {
    display: none;
}

#signUp-tab {
    cursor: not-allowed;
    display: none;
}

.loginpage .amplify-tabs__panel .amplify-tabs__panel--active{
    border: none;
}

#signIn-panel {
    background-color: white;
    border: none;
}
    
.amplify-tabs__panel,
.amplify-tabs__panel--active {
    border: none;
    border-radius: 20px;
}

[data-amplify-authenticator] [data-amplify-router]{
    border: none;
    box-shadow: none;
    background:none;
}

.amplify-label{
    color:gray;
}
